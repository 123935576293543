import React, { useContext, Suspense, useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/Style.css";
import "./styles/line-awesome.min.css";
import data from "./json/apple-app-site-association";
import { Outlet, useLocation, useParams } from "react-router-dom";

//import ThankYou from './components/pages/ThankYou';
//import Register from "./components/pages/Register";
//import EmailConfirmed from "./components/pages/EmailConfirmed";
//import GreatJob from "./components/pages/GreatJob";
//import AddLogo from "./components/pages/AddLogo";
//import AddProfilePicture from "./components/pages/AddProfilePicture";
//import AddYourDetails from "./components/pages/AddYourDetails";
//import BannerColours from "./components/pages/BannerColour";
import Password from "./components/pages/Password";
//import Login from "./components/pages/Login"
// import ProfilePage from "./components/pages/ProfilePage";
//import ShortBio from "./components/pages/ShortBio";
//import YourDetails from "./components/pages/YourDetails";
//import Scan from "./components/pages/Scan";
//import Dashboard from "./components/pages/Dashboard";
//import SortOrder from "./components/pages/SortOrder";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AuthContext from "./store/auth-context";

import Loader from "./Library/Loader";
import NotFound from "./components/pages/NotFound";
import BackgroundAndProfileColor from "./components/pages/BackgroundAndProfileColor";
import { getUserData } from "./Library/Helper";
import axios from "axios";
import Initial from "./components/pages/Initial";
import MySpace from "./components/pages/my-space";
import Reports from "./components/pages/reports";
import Template from "./components/pages/Template";
import Theme from "./components/pages/Theme";
import EventImages from "./components/pages/EventImages";
import EventImagesCollection from "./components/pages/EventImagesCollection";
import UploadEventImages from "./components/pages/UploadEventImages";
import PaymentSuccess from "./components/pages/PaymentSuccess";
import PaymentFailure from "./components/pages/PaymentFailure";
const ThankYou = React.lazy(() => import("./components/pages/ThankYou"));
const SuccessProduct = React.lazy(() =>
  import("./components/pages/SuccessProduct")
);
const SuccessUser = React.lazy(() => import("./components/pages/SuccessUser"));
const SampleLanding = React.lazy(() =>
  import("./components/pages/SampleLanding")
);
const Register = React.lazy(() => import("./components/pages/Register"));
const Youtube = React.lazy(() => import("./components/pages/Youtube"));
const Customrfnumbers = React.lazy(() =>
  import("./components/pages/Customrfnumbers")
);

const EmailConfirmed = React.lazy(() =>
  import("./components/pages/EmailConfirmed")
);
const GreatJob = React.lazy(() => import("./components/pages/GreatJob"));
const AddLogo = React.lazy(() => import("./components/pages/AddLogo"));
const Addconnection = React.lazy(() =>
  import("./components/pages/Addconnection")
);
const EventFolderFront = React.lazy(() =>
  import("./components/pages/EventFolderFront")
);

const FrontEventImagesCollection = React.lazy(() =>
  import("./components/pages/FrontEventImagesCollection")
);

const ConnectionFields = React.lazy(() =>
  import("./components/pages/ConnectionFields")
);

const Sampleportal = React.lazy(() =>
  import("./components/pages/Sampleportal")
);
const Connections = React.lazy(() => import("./components/pages/Connections"));
const SocialSingleUser = React.lazy(() =>
  import("./components/pages/SocialSingleUser")
);
const SocialProducts = React.lazy(() =>
  import("./components/pages/SocialProducts")
);
const Createicon = React.lazy(() => import("./components/pages/Createicon"));
const GoogleReview = React.lazy(() =>
  import("./components/pages/GoogleReview")
);

const Connectionuser = React.lazy(() =>
  import("./components/pages/Connectionuser")
);
const AddProfilePicture = React.lazy(() =>
  import("./components/pages/AddProfilePicture")
);
const AddYourDetails = React.lazy(() =>
  import("./components/pages/AddYourDetails")
);
const BannerColours = React.lazy(() =>
  import("./components/pages/BannerColour")
);
//const Password=React.lazy(()=>import("./components/pages/Password"));
const Login = React.lazy(() => import("./components/pages/Login"));
const ShortBio = React.lazy(() => import("./components/pages/ShortBio"));
const YourDetails = React.lazy(() => import("./components/pages/YourDetails"));
const Scan = React.lazy(() => import("./components/pages/Scan"));
const Dashboard = React.lazy(() => import("./components/pages/Dashboard"));
const SortOrder = React.lazy(() => import("./components/pages/SortOrder"));
const ConfirmSocialOtp = React.lazy(() =>
  import("./components/pages/ConfirmSocialOtp")
);

const ForgetPassword = React.lazy(() =>
  import("./components/pages/ForgetPassword")
);
const DeactivateUser = React.lazy(() =>
  import("./components/pages/DeactivateUser")
);
const RfSave = React.lazy(() => import("./components/pages/RfSave"));
const ScanQR = React.lazy(() => import("./components/pages/ScanQR"));

// import ScrollToTop from "./components/layout/ScrollToTop";

function App() {
  const authCtx = useContext(AuthContext);
  // console.log(authCtx);
  const pull_ComponentClass = (compClass) => {
    return compClass;
  };

  const [isPathMatch, setIsPathMatch] = useState(false);

  const { userData } = getUserData();

  const MainLayout = () => {
    // const [backgroundColor, setBackgroundColor] = useState("");

    // useEffect(() => {
    //   // setBackgroundColor(userData?.backgroundcolour);
    //   if (
    //     userData?.backgroundcolour &&
    //     window.location.pathname === "/dashboard"
    //   ) {
    //     setBackgroundColor(userData?.backgroundcolour);
    //   } else {
    //     if (params.RfNo !== undefined) {
    //       getUser();
    //     }
    //   }
    // }, []);

    // // const getUser = async () => {
    // //   try {
    // //     let rfNo = params.RfNo;

    // //     console.log({ rfNo });
    // //     let token = getUserData().token;
    // //     const config = {
    // //       headers: { Authorization: `Bearer ${token}` },
    // //     };
    // //     const res = await axios.get(
    // //       process.env.REACT_APP_API_BASE_URL + rfNo,
    // //       config
    // //     );

    // //     console.log({ res });

    // //     if (res.data) {
    // //       setBackgroundColor(res.data.data.profileDetail.backgroundcolour);
    // //     }
    // //   } catch (error) {
    // //     console.log({ error });
    // //   }
    // // };

    return (
      <>
        {/* <style>
      .card-wraper .card-bg .bg-img::after{background: '#ffff';}
     </style> */}
        <div
          className="container container-wraper"
          // style={{
          //   width: "100%",
          //   height: "100%",
          //   background: backgroundColor,
          // }}
        >
          <Outlet />
        </div>
      </>
    );
  };

  const isLoggedIn = localStorage.getItem("IsLoggedIn");
  const checkRfNo = localStorage.getItem("checkRfNo");

  if (window.location.pathname === "/apple-app-site-association") {
    return <pre>{JSON.stringify(data, null, 2)}</pre>;
  }

  return (
    <section className="homescreen" id="homescreen">
      <div className="container">
        <Suspense fallback={<Loader />}>
          {/* <Router> */}
          {/* <ScrollToTop /> */}
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/" element={<Login />} />
            <Route element={<MainLayout />}>
              <Route path="/:RfNo/Addconnection" element={<Addconnection />} />
              <Route
                path="/:RfNo/EventFolderFront"
                element={<EventFolderFront />}
              />
              <Route
                path="/front-images/:folderId"
                element={<FrontEventImagesCollection />}
              />
              <Route path="/:RfNo" element={<Scan />} />
              <Route path="init/:RfNo" element={<Initial />} />
              <Route path="/Sampleportal" element={<Sampleportal />} />
              <Route path="/forgetPassword/" element={<ForgetPassword />} />
              <Route path="/DeactivateUser/" element={<DeactivateUser />} />
              <Route path="/ConnectionFields" element={<ConnectionFields />} />
              <Route
                path="/ConfirmSocialOtp/:rfId/:userId"
                element={<ConfirmSocialOtp />}
              />
              <Route path="/aibfail" element={<PaymentFailure />} />
              <Route path="/aibsuccess" element={<PaymentSuccess />} />

              <Route path="/register/:RfNo" element={<Register />} />
              <Route path="/youtube" element={<Youtube />} />
              <Route path="/Customrfnumbers" element={<Customrfnumbers />} />
              <Route path="/change-name" element={<Register />} />
              <Route path="/thankyou" element={<ThankYou />} />
              <Route path="/googlereview" element={<GoogleReview />} />
              <Route path="/SuccessProduct" element={<SuccessProduct />} />
              <Route path="/SuccessUser" element={<SuccessUser />} />
              <Route path="/SampleLanding" element={<SampleLanding />} />
              <Route
                path="/account/confirm/:confirmation_code"
                element={<EmailConfirmed />}
              />
              <Route path="/greatjob" element={<GreatJob />} />
              <Route path="/password" element={<Password />} />

              <Route
                path="/password/reset/:confirmationCode"
                element={<Password />}
              />
              <Route path="/change-password" element={<Password />} />
              {/* <Route path="/profile-page" element={<ProfilePage />} /> */}
              {authCtx && authCtx.isLoggedIn && (
                <>
                  <Route path="/dashboard" element={<Dashboard />} />
                  <Route path="/short-bio" element={<ShortBio />} />
                  <Route path="/banner-colour" element={<BannerColours />} />
                  <Route
                    path="/add-your-details"
                    element={<AddYourDetails />}
                  />
                  <Route
                    path="/add-profile-picture"
                    element={<AddProfilePicture />}
                  />
                  <Route path="/add-logo" element={<AddLogo />} />
                  <Route path="/connections" element={<Connections />} />
                  <Route path="/SocialProducts" element={<SocialProducts />} />
                  <Route
                    path="/SocialSingleUser/:conId"
                    element={<SocialSingleUser />}
                  />
                  <Route path="/Createicon" element={<Createicon />} />
                  <Route
                    path="/connectionuser/:conId"
                    element={<Connectionuser />}
                  />
                  <Route path="/your-details" element={<YourDetails />} />
                  <Route
                    path="/profile-background-color"
                    element={<BackgroundAndProfileColor />}
                  />
                  <Route path="/my-space" element={<MySpace />} />
                  <Route path="/reports" element={<Reports />} />
                  <Route path="/template" element={<Template />} />
                  <Route path="/theme" element={<Theme />} />
                  <Route path="/event-images" element={<EventImages />} />
                  <Route
                    path="/event-images/:folderId"
                    element={<EventImagesCollection />}
                  />
                  <Route
                    path="/upload-event-images"
                    element={<UploadEventImages />}
                  />
                </>
              )}
              <Route path="/sort-order" element={<SortOrder />} />
              <Route path="/rfsave" element={<RfSave />} />
              <Route path="/qr-scan" element={<ScanQR />} />

              <Route path="*" element={<NotFound />} />
            </Route>
          </Routes>
          {/* </Router> */}
        </Suspense>

        {window.location.pathname === `/${checkRfNo}` ||
        isLoggedIn !== "true" ? (
          <div className="copyright-content">
            <a href="https://www.tapitag.co/" target="_blank" rel="noreferrer">
              Powered by TAPiTAG
            </a>
          </div>
        ) : null}
      </div>
    </section>
  );
}

export default App;

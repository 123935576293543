import React from 'react';
import Layout from "../layout/Layout";
import { IsLoggedIn } from "../../Library/Helper";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { getUserData } from "./../../Library/Helper";
import Axios from "axios";

const MySpace = () => {
    const navigate = useNavigate();

    const userType = localStorage.getItem("userType");
    const LoggedInStatus = IsLoggedIn();
    const BackButton = LoggedInStatus;
    let [loading, setLoading] = useState(true);
    const [userData, setUserData] = useState(false);

    useEffect(() => {
        let token = getUserData().token;
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        };
        Axios.get(process.env.REACT_APP_API_BASE_URL + "auth/multiprofile_list", config)
            .then(function (response) {
                console.log(response.data);
                setUserData(response.data.data[0]);
                setLoading(false);
            })
            .catch(function (error) {
                console.log(error);
                setLoading(false);
            });
    }, []);
    return (
        <Layout bottom="true" BackButton={BackButton}>
            <div className="col-md-12">
                <h4 className="mb-4">Select Profile</h4>
                <div className="profile-list-wraper">
                    <div className="user-card-wraper active">
                        <div className="user-image">
                            <img src={process.env.REACT_APP_Img_BASE_URL + userData.personal_image} alt="img" />
                        </div>
                        <div className="user-name">
                            <h4>{userData.personal_name}</h4>
                        </div>
                        <div className="user-edit-btn">
                            <button type="button" className="btn"><i className="las la-edit"></i></button>
                        </div>
                    </div>
                    <div className="user-card-wraper">
                        <div className="user-image">
                        <img src={process.env.REACT_APP_Img_BASE_URL + userData.business_image} alt="img" />
                        </div>
                        <div className="user-name">
                            <h4>{userData.business_name}</h4>
                        </div>
                        <div className="user-edit-btn">
                            <button type="button" className="btn"><i className="las la-edit"></i></button>
                        </div>
                    </div>
                    <div className="user-card-wraper">
                        <div className="user-image">
                        <img src={process.env.REACT_APP_Img_BASE_URL + userData.other_image} alt="img" />
                        </div>
                        <div className="user-name">
                            <h4>{userData.other_name}</h4>
                        </div>
                        <div className="user-edit-btn">
                            <button type="button" className="btn"><i className="las la-edit"></i></button>
                        </div>
                    </div>
                </div>
            </div>
            
        </Layout>
    )
}

export default MySpace

import React from "react";
import Layout from "../layout/Layout";
import { useNavigate } from "react-router-dom";

const PaymentFailure = () => {
  const navigate = useNavigate();

  return (
    <Layout>
      <div className="payment-success">
        <div className="successicon">
          <img src="/assets/img/remove.png" alt="failure" />
        </div>
        <h4>Payment Failed!</h4>
        <p>Unfortunately payment was rejected</p>
        <button
          onClick={() => {
            navigate("/");
          }}
          className="btn btnUI-1"
        >
          Try Again
        </button>
      </div>
    </Layout>
  );
};

export default PaymentFailure;

import React from 'react';
import Layout from "../layout/Layout";
import { IsLoggedIn } from "../../Library/Helper";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { getUserData } from "./../../Library/Helper";
import Axios from "axios";

const Template = () => {
    const navigate = useNavigate();
    const userType = localStorage.getItem("userType");
    const LoggedInStatus = IsLoggedIn();
    const BackButton = LoggedInStatus;
    let [loading, setLoading] = useState(true);
    const [userData, setUserData] = useState(false);
    const [selectedTemp, setSelectedTemp] = useState();

    useEffect(() => {
        let token = getUserData().token;
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        };
        Axios.get(process.env.REACT_APP_API_BASE_URL + "auth/get_all_template", config)
            .then(function (response) {
                console.log(response);
                setUserData(response.data);
                setLoading(false);
            })
            .catch(function (error) {
                console.log(error);
                setLoading(false);
            });
    }, []);

    const savetemplate = (templateId) => {
        console.log(templateId)
        let token = getUserData().token;
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        };
        Axios.post(
            process.env.REACT_APP_API_BASE_URL + "auth/add_user_template",
            { template_id: templateId, type: userType },
            config
        )
            .then(function (response) {
                console.log(response.data);
                setLoading(false);
                navigate("/dashboard");
            })
            .catch(function (error) {
                console.log(error);
                setLoading(false);
            });
    };



    return (
        <Layout bottom="true" BackButton={BackButton}>
            <div className="col-md-12">
                <h4 className="mb-4">Templates</h4>
                <div className='row'>
                    {userData &&
                        userData.templates.map(function (
                            { id, label, value, image, isSelected },
                            index
                        ) {
                            return (
                                <div className='col-6' key={index}>
                                    <div className='template-image-wraper radio-inputs'>
                                        <div className={isSelected ? 'radio-tile selected' : 'radio-tile'}>
                                            <span className="radio-label">{label}</span>
                                            <div className='radio-icon image-preview-wraper'>
                                                <img src={
                                                    process.env.REACT_APP_Img_BASE_URL +
                                                    image
                                                } alt="template Preview" className="img-fluid" />

                                            </div>
                                            <div className='text-center mb-4'>
                                                <button className="btnUI-1" type='button' onClick={() => savetemplate(id)} disabled={isSelected}>Apply</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}

                </div>
            </div>
        </Layout>
    )
}

export default Template


import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import ModalWrapper from "../common/ModalWrapper";
import Axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { getUserData } from "./../../Library/Helper";


const FolderList = (props) => {
    const navigate = useNavigate();

    const [isToggled, setIsToggled] = useState(false);
    const handleToggle = () => {
        setIsToggled(!isToggled); // Toggle the state
    };
    const [folderID, setFolderId] = useState(null);
    const [isModal, setIsModal] = useState(false);
    const [isRenameModal, setIsRenameModal] = useState(false);
    const [isViewModal, setIsViewModal] = useState(false);
    const [folderName, setFolderName] = useState();
    const [folderimageview, setFolderImageview] = useState();
    const [folderImage, setFolderImage] = useState([])


    const deleteFolder = () => {
        let token = getUserData().token;
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        };
        Axios.post(
            process.env.REACT_APP_API_BASE_URL + "auth/delete_folder/",
            { folderID, folderID },
            config
        )
            .then((res) => {
                setIsModal(false);
                props.getEventFolder()
                // navigate("/dashboard");
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const directShare = (folderId) => {
        let token = getUserData().token;
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        };
        Axios.post(
            process.env.REACT_APP_API_BASE_URL + "auth/folder_direct_share/",
            { folderID: folderId },
            config
        )
            .then((res) => {
                props.getEventFolder()
                // navigate("/dashboard");
            })
            .catch((err) => {
                console.log(err);
            });
    };
    const updateFolder = () => {
        let token = getUserData().token;
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        };
        const formData = new FormData();
        formData.append("folder_name", folderName);
        formData.append(`image`, folderImage)
        formData.append(`folderID`, folderID)
        Axios.post(
            process.env.REACT_APP_API_BASE_URL + "auth/update_folder/",
            formData,
            config
        )
            .then((res) => {
                setIsRenameModal(false);
                props.getEventFolder()
                // navigate("/dashboard")
            })
            .catch((err) => {
                console.log(err);
            })
    }

    const [isChecked, setIsChecked] = useState(false);
    const handleCheckboxChange = (event, folderId) => {
        setIsChecked(event.target.checked);
        directShare(folderId);
    };

    useEffect(() => {
        console.log(props.checked)
        // Set isChecked based on the direct_share prop
        if (props.checked == 1) {
            setIsChecked(false);
        } else {
            setIsChecked(true);
        }
    }, [props.checked]);

    return (
        <div className='col-6'>
            <div className='folder-box'>
                <div className='dropdown'>
                    <button type='button' className={`btn ${isToggled ? 'active' : 'inActive'}`} onClick={handleToggle}><i className={`${isToggled ? 'fa fa-times' : 'fa fa-ellipsis-v'}`}></i></button>
                    <div className={`dropdown-menu ${isToggled ? 'show' : ''}`}>
                        <button type='button' className='dropdown-item' folderid={props.folderID} onClick={() => {
                            setIsToggled(false)
                            setIsViewModal(true);
                            setFolderId(props.folderID);
                            setFolderName(props.folderHeading)
                            setFolderImageview(props.image)

                        }} >View</button>

                        <button type='button' className='dropdown-item' folderid={props.folderID} onClick={() => {
                            setIsToggled(false)
                            setIsModal(true);
                            setFolderId(props.folderID)
                        }} >Delete</button>
                        <button type='button' className='dropdown-item' folderid={props.folderID} onClick={() => {
                            setIsToggled(false)

                            setIsRenameModal(true);
                            setFolderId(props.folderID);
                            setFolderName(props.folderHeading)
                        }} >Rename</button>

                    </div>
                </div>
                <Link to={props.folderURL}>
                    <div className='folder-wrap'>
                        <i className="fa-solid f-44 fa-folder txt-warning"></i>
                        <div className="folder-text">
                            <h6>{props.folderHeading}</h6>
                            <p>
                                <span>{props.fileCount} files</span>
                                {/* <span>
                                    {props.fileTime}
                                </span> */}
                            </p>
                        </div>
                    </div>
                </Link>
                <div className={`custom-button ${isChecked ? 'active' : ''}`}>
                    <input type="checkbox" className="checkbox"
                        checked={isChecked}
                        onChange={(e) => handleCheckboxChange(e, props.folderID)}
                    />
                    <div className="knobs">
                        <span></span>
                    </div>
                    <div className="layer"></div>
                </div>
            </div>
            {isModal && (
                <ModalWrapper
                    heading="Create folder"
                    show={isModal}
                    closeHandler={() => setIsModal(false)}
                    clickHandler={deleteFolder}
                    submitText="Submit"
                >
                    <p>Are you sure you want to delete this connection?</p>
                </ModalWrapper>
            )}

            {isViewModal && (
                <ModalWrapper
                    heading={folderName}
                    show={isViewModal}
                    closeHandler={() => setIsViewModal(false)}
                >
                    <img src={process.env.REACT_APP_Img_BASE_URL + folderimageview} alt={folderimageview} />
                </ModalWrapper>
            )}

            {isRenameModal && (
                <ModalWrapper
                    heading="Update folder"
                    show={isRenameModal}
                    closeHandler={() => setIsRenameModal(false)}
                    clickHandler={updateFolder}
                    submitText="Submit"
                >
                    <input
                        type="text"
                        className="form-control"
                        id="Rename"
                        value={folderName}
                        placeholder="Rename Name"
                        onChange={(e) => {
                            setFolderName(e.target.value);
                        }}
                    />
                    <div className="file-input mt-2">
                        <input type='file' onChange={(e) => {
                            setFolderImage(e.target.files[0])
                        }}
                            id="file-input"
                            className="file-input__input"
                        />
                        <label className="file-input__label" for="file-input">
                            <i className="las la-cloud-upload-alt"></i>
                            <span>Upload file</span>
                        </label>
                    </div>
                </ModalWrapper>
            )}
        </div>
    )
}

export default FolderList

import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Layout from "../layout/Layout";
import Home from "./Home";
import Axios from "axios";
import { getnavigateUrl, getUserData } from "../../Library/Helper";

const Initial = () => {
  const { RfNo } = useParams();
  let [loading, setLoading] = useState(false);
  const [cardType, setCardType] = useState(null);
  const [PrivacyStatus, setPrivacyStatus] = useState(null);
  const userType = localStorage.getItem("userType");

  const navigate = useNavigate();

  Axios.get(`${process.env.REACT_APP_API_BASE_URL + RfNo}?type=${userType}`)
    .then(function (response) {
      // console.log(response.data.data.privacystatus);
      setCardType(response?.data?.card_type);
      setPrivacyStatus(response.data.data.privacystatus);

      if (response.data.status == 1 || response.data.status == "redirect") {
        navigate("/" + RfNo);
      }
    })
    .catch(function (error) {
      console.log(error);
      setLoading(false);
      // setMsg(error.response.data.error.message);
    });
  return (
    <>
      {/* <Layout bottom="false"></Layout> */}
      <Home cardType={cardType} RfNo={RfNo} PrivacyStatus={PrivacyStatus} />
    </>
  );
};

export default Initial;

import Layout from "../layout/Layout";
import { useNavigate } from "react-router-dom";

const Home = (props) => {
  console.log(props)
  const navigate = useNavigate();
  const homeEventHandler = () => {
    navigate("/register/" + props.RfNo, { state: props.RfNo });
  };

  return (
    <>
      <Layout
        RfNo={props.RfNo}
        cardType={props.cardType}
        mainDivClass="homeLogo"
        gifLogo="true"
        bottom="true"
        bottomClickeventhandler={homeEventHandler}
        buttontext="Begin Setup"
        logohideMbCls="true"
      />
      
      {props.PrivacyStatus == 'true' &&(
        <div className="column bottom">
          <p className="text-center">Click Begin Setup to opt in to our Privacy Policy </p>
          <a className="text-center" href="https://projectsuperone.s3.us-west-2.amazonaws.com/uploads/pdf/GL8RGpRmfsANCNVmOZU2YlaolSoEDjeTGBDnZqX1.pdf" target="__blank">View privacy policy</a>
        </div>
      )
      }
      {/* {props.PrivacyStatus == 'true' ? (
  <div className="column bottom">
    <p className="text-center">Click Begin Setup to opt in to our Privacy Policy </p>
    <a className="text-center" href="https://projectsuperone.s3.us-west-2.amazonaws.com/uploads/pdf/GL8RGpRmfsANCNVmOZU2YlaolSoEDjeTGBDnZqX1.pdf" target="__blank">View privacy policy</a>
  </div>
) : (
  <div className="column bottom">
    <p className="text-center">Click Begin Setup to opt in to our Privacy Policy </p>
    <a className="text-center" href="https://projectsuperone.s3.us-west-2.amazonaws.com/uploads/pdf/Q5oUt536suXvmGxE2UHfFt0CK7fucXsOw6Zb1aWs.pdf" target="__blank">View privacy policy</a>
  </div>
)} */}

    </>
  );
};
export default Home;

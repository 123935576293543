import React from 'react';
import Layout from "../layout/Layout";
import { IsLoggedIn } from "../../Library/Helper";
import { useEffect, useState } from "react";
import { getUserData } from "./../../Library/Helper";
import Axios from "axios";

const Reports = () => {
    const userType = localStorage.getItem("userType");
    const LoggedInStatus = IsLoggedIn();
    const BackButton = LoggedInStatus;
    let [loading, setLoading] = useState(true);
    const [userData, setUserData] = useState(false);
    const [userDetails, setUserDetails] = useState(false);



    useEffect(() => {
        let token = getUserData().token;
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        };
        Axios.get(process.env.REACT_APP_API_BASE_URL + "auth/appanalytics", config)
            .then(function (response) {
                console.log(response.data.analytics);
                setUserData(response.data.analytics);
                setUserDetails(response.data.analytics.userdetails);
                setLoading(false);
            })
            .catch(function (error) {
                console.log(error);
                setLoading(false);
            });
    }, []);

    return (
        <Layout bottom="true" BackButton={BackButton}>
            <div className="col-md-12">
                <h4 className="mb-4">Reports</h4>
                <div className="profile-list-wraper">
                    <div className="user-card-wraper">
                        <div className="user-image">
                            <img src= {process.env.REACT_APP_Img_BASE_URL + userDetails.image }alt={userDetails.first_name} />
                        </div>
                        <div className="user-name">
                            <h4> {userDetails.first_name}  {userDetails.last_name}</h4>
                            <p>TAP Streak {userData.sharebuttoncounts} days in a row</p>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-6'>
                        <div className='user-card-wraper mt-3'>
                            <div className='card-data-wraper'>
                                <h4>Tap</h4>
                                <p>{userData.todayTaps}</p>
                                <div className='chart-box-wraper'>
                                    <img src='icon-chart.png' alt='img' />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className='user-card-wraper mt-3'>
                            <div className='card-data-wraper'>
                                <h4>Icon Tap</h4>
                                <p>{userData.linkTaps}</p>
                                <div className='chart-box-wraper'>
                                    <img src='icon-chart.png' alt='img' />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className='user-card-wraper mt-3'>
                            <div className='card-data-wraper'>
                                <h4>New Connections</h4>
                                <p>{userData.vcftaps}</p>
                                <div className='chart-box-wraper'>
                                    <img src='icon-chart.png' alt='img' />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className='user-card-wraper mt-3'>
                            <div className='card-data-wraper'>
                                <h4>Contacts Downloads</h4>
                                <p>{userData.contactbuttontaps}</p>
                                <div className='chart-box-wraper'>
                                    <img src='icon-chart.png' alt='img' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Reports;

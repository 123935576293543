import React, { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import Layout from "../layout/Layout";
import { IsLoggedIn } from "../../Library/Helper";

const UploadEventImages = () => {
    const LoggedInStatus = IsLoggedIn();
    const BackButton = LoggedInStatus;

   
   
    const [selectedFiles, setSelectedFiles] = useState([]);

    const onDrop = useCallback((acceptedFiles) => {
      const newFiles = acceptedFiles.map((file) => {
        return {
          file,
          preview: URL.createObjectURL(file),
        };
      });

      setSelectedFiles((prevFiles) => [...prevFiles, ...newFiles]);
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
      onDrop,
      accept: 'image/*',
      multiple: true,
    });
   
  return (
    <Layout bottom="true" BackButton={BackButton}>
        <div className="col-md-12">
            <div className='create-folder-btn-wraper'>
                <h4>Upload File</h4>
            </div>
            <div className="upload-container">
              <div {...getRootProps()} className={`dropzone ${isDragActive ? 'active' : ''}`}>
                <input {...getInputProps()} />
                <div className="upload-icon">📂</div>
                <p>{isDragActive ? 'Drop the files here ...' : 'Drag and drop files here, or click to select them'}</p>
              </div>
              <div className="preview-container">
                {selectedFiles.map((item, index) => (
                  <div key={index} className="file-container">
                    <img src={item.preview} alt={`Preview ${index}`} className="file-preview" />
                    <p className="file-info">{item.file.name}</p>
                  </div>
                ))}
              </div>
            </div>
        </div>
    </Layout>
  )
}

export default UploadEventImages

import { createSlice } from "@reduxjs/toolkit";

const commonSlice = createSlice({
  name: "common",
  initialState: {
    isTapiTag: null,
  },
  reducers: {
    setIsTappiTag: (state, action) => {
      state.isTapiTag = action.payload;
    },
  },
});

export const { setIsTappiTag } = commonSlice.actions;

export default commonSlice.reducer;

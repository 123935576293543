import { useState, useEffect } from "react";
import {
  Link,
  Router,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { IsLoggedIn } from "../../Library/Helper";
import { getUserData, getnavigateUrl } from "./../../Library/Helper";
import Axios from "axios";
import { useSelector } from "react-redux";
import ModalWrapper from "../common/ModalWrapper";

const Layout = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();
  const LoggedInStatus = IsLoggedIn();
  let [loading, setLoading] = useState(false);
  const [securityPinModal, setSecurityPinModal] = useState(false);
  const [securityPinError, setSecurityPinError] = useState({
    pin: null,
    error: null,
  });
  const [securityPin, setSecurityPin] = useState("");
  const [userData, setUserData] = useState(false);
  const { isTapiTag } = useSelector((state) => state.common);
  const userType = localStorage.getItem("userType");
  var mainDivClass = "column info ";
  var logocls = "logo mb-5";
  if (props.mainDivClass) {
    mainDivClass = "column info " + props.mainDivClass;
  }
  if (props.logohideMbCls) {
    logocls = "logo";
  }
  const bottomButtonHandler = () => {
    if (props.cardType === "retail") {
      setSecurityPinModal(true);
    } else {
      props.bottomClickeventhandler();
    }
  };

  console.log("isTapitag", isTapiTag);

  const { RfNo } = useParams();
  const location = useLocation();

  //   useEffect(() => {
  //     if (RfNo) {
  //       //   if (!tapi) {
  //       getUserType();
  //       //   }
  //     }
  //   }, [RfNo]);

  const deleteButtonHandler = (url) => {
    // console.log(url);
    let userId = getUserData().userData.id;
    let token = getUserData().token;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    if (url == "bannerlogo") {
      var apipath = "auth/deletebannerimage";
    } else if (url == "deleteprofile") {
      var apipath = "auth/deleteprofileimage";
    } else {
    }
    Axios.post(
      process.env.REACT_APP_API_BASE_URL + apipath,
      { id: userId, type: userType },
      config
    )

      .then(function (response) {
        navigate("/dashboard");
      })
      .catch(function (error) {
        setLoading(false);
      });
  };
  const backButtonHandler = () => {
    //navigate(-1)
    if (props?.backButtonUrl) {
      navigate(props?.backButtonUrl);
    } else {
      window.history.back();
    }
  };
  const handleClick = (url) => {
    if (url == "/login") {
      localStorage.clear();
    }
    navigate(url);
  };

  const checkPinHandler = async () => {
    let token = getUserData().token;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    setLoading(true);
    const response = await Axios.post(
      process.env.REACT_APP_API_BASE_URL + "auth/checkcardsecuritycode",
      { name: props.RfNo, securitypin: securityPin, type: userType },
      config
    );
    setLoading(false);
    if (response?.data?.status === true) {
      props.bottomClickeventhandler();
    } else {
      setSecurityPinError((prev) => {
        return { ...prev, error: response?.data?.msg };
      });
    }
  };
  return (
    <>
      <div className="column top">
        {props.BackButton && (
          <div
            className="back-btn"
            onClick={() => backButtonHandler(props.backButtonUrl)}
          >
            <Link>
              <i className="las la-arrow-left"></i>
            </Link>
          </div>
        )}
      </div>
      <div className={mainDivClass} id="profileScreenWidth">
        <div className={logocls}>
          {/* {isTapitag === null ? (
            <></>
          ) : (
            isTapitag === true &&
            (// <>
            //   {!props.gifLogo && !props.hideLogo && (
            //     <img src="/assets/img/logo.png" alt="Tapitag" />
            //   )}
            //   {props.gifLogo && <img src="/Animated-Logo.gif" alt="Tapitag" />}
            // </>
            <img src="/assets/img/logo.png" alt="Tapitag" />)(
              isTapitag === false && (
                <img src="https://suitsupply.tapitag.co/front/img/TAPiTAG-Logo.png" />
              )
            )
          )} */}
          {/* {isTapitag === true ? (
            <img src="/assets/img/logo.png" alt="Tapitag" />
          ) : (
            <img src="https://suitsupply.tapitag.co/front/img/TAPiTAG-Logo.png" />
          )} */}
          {location.pathname === `/createicon` && (
            <>
              {/* {!props.gifLogo && !props.hideLogo && ( */}
              <img src="/assets/img/logo.png" alt="Tapitag" />
              {/* )} */}
              {/* {props.gifLogo && <img src="/Animated-Logo.gif" alt="Tapitag" />} */}
            </>
          )}
          {isTapiTag === false && (
            <img src="https://suitsupply.tapitag.co/front/img/TAPiTAG-Logo.png" />
          )}
          {(location.pathname === "/" ||
            location.pathname === "/login" ||
            location.pathname === `/init/${RfNo}` ||
            location.pathname === `/register/${RfNo}`) && (
            <img src="/assets/img/logo.png" alt="Tapitag" />
          )}
          {/* {props.isLogin && <img src="/assets/img/logo.png" alt="Tapitag" />} */}
        </div>
        {props.children}
      </div>

      {props.buttontext && (
        <div className="column bottom">
          <button onClick={bottomButtonHandler} className="btnUI-1">
            {LoggedInStatus ? "confirm" : props.buttontext}
          </button>
        </div>
      )}
      {props.deletebutton && (
        <div className="column bottom">
          <button
            href="javascript:void(0)"
            onClick={(e) => {
              deleteButtonHandler(props.buttonfor);
            }}
            className="btnUI-1"
          >
            {LoggedInStatus ? "delete" : props.deletebutton}
          </button>
        </div>
      )}
      <ModalWrapper
        show={securityPinModal}
        clickHandler={async () => {
          if (securityPin === "") {
            setSecurityPinError({
              pin: true,
            });
          } else {
            await checkPinHandler();
          }
        }}
        closeHandler={() => {
          setSecurityPinModal(false);
        }}
        heading="Security Pin"
        submitText={loading ? "Submitting..." : "Submit"}
      >
        <div className="mb-3">
          <input
            type="number"
            autoComplete="off"
            className="form-control"
            id="lName"
            onChange={(e) => {
              setSecurityPin(e.target.value);
              setSecurityPinError((prev) => {
                return { ...prev, pin: false };
              });
            }}
            placeholder="Please enter a pin"
          />
          {securityPinError?.pin && (
            <span className="text-danger fa-xs">
              Please enter a security pin
            </span>
          )}
        </div>
        {!!securityPinError?.error && (
          <div className="alert alert-danger">
            <p>{securityPinError?.error}</p>
          </div>
        )}
      </ModalWrapper>
    </>
  );
};
export default Layout;

import React from 'react';
import Layout from "../layout/Layout";
import { IsLoggedIn } from "../../Library/Helper";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { getUserData } from "./../../Library/Helper";
import Axios from "axios";
import ModalWrapper from "../common/ModalWrapper";

import FolderList from '../common/FolderList';

import '../../styles/google-fonts.css';




const EventImages = () => {
    const navigate = useNavigate();
    const userType = localStorage.getItem("userType");
    const LoggedInStatus = IsLoggedIn();
    const BackButton = LoggedInStatus;
    let [loading, setLoading] = useState(true);
    const [folderListName, setFolderListName] = useState(false);
    const [isModal, setIsModal] = useState(false);
    const [folderName, setFolderName] = useState();
    const [folderImage,setFolderImage] = useState([])
    const getEventFolder = () =>{
        let token = getUserData().token;
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        };
        Axios.get(process.env.REACT_APP_API_BASE_URL + "auth/get_event_folder", config)
            .then(function (response) {
                console.log(response.data.data);
                setFolderListName(response.data);
                setLoading(false);
            })
            .catch(function (error) {
                console.log(error);
                setLoading(false);
            });
    }
    useEffect(() => {
        getEventFolder()
    }, []);

    const createFolder = ({folderName,folderImage}) => {
        let token = getUserData().token;
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        };
        const formData = new FormData();
        formData.append("folder_name",folderName);
            formData.append(`image`,folderImage)
        Axios.post(
            process.env.REACT_APP_API_BASE_URL + "auth/create_folder/",
            formData,
            config
        )
            .then((res) => {
                setIsModal(false);
                getEventFolder()
            })
            .catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {
        document.body.classList.add('folder-list-page');
        
        // Cleanup function to remove the class when the component unmounts
        return () => {
          document.body.classList.remove('folder-list-page');
        };
    }, []);

    const options = [
        { value: 'trocchi-regular', label: '"Trocchi", serif' },
        { value: 'zeyada-regular', label: '"Zeyada", cursive' },
        { value: 'yellowtail-regular', label: '"Yellowtail", cursive' }
    ]

    const [selectedFont, setSelectedFont] = useState('default'); // Initial font

    const handleFontChange = (event) => {
        setSelectedFont(event.target.value);
    };




    return (
        <Layout bottom="true" BackButton={BackButton}>
            <div className="col-md-12">
                <div className='create-folder-btn-wraper'>
                    <div className='folder-list-name'><h4>Folder List</h4></div>
                    <div className='folder-create-wraper'>
                        <button type='button' onClick={() => setIsModal(true)} className='btn'>Create Folder <i className="fas fa-plus"></i></button>
                    </div>
                </div>
                <div className='row mt-5'>
                    {folderListName &&
                        folderListName.data.map(function (
                            { id, folder_name,file_count,created_at,direct_share,image},
                            index
                        ) {
                            return (
                                <FolderList getEventFolder={getEventFolder} folderURL={`/event-images/${id}`} folderID={id} folderHeading={folder_name} fileCount={file_count} fileTime={created_at} key={index} checked={direct_share} image={image} />
                            )
                        })}
                </div>
                {isModal && (
                    <ModalWrapper
                        heading="Create folder"
                        show={isModal}
                        closeHandler={() => setIsModal(false)}
                        clickHandler={()=>createFolder({folderName,folderImage})}
                        submitText="Submit"
                    >
                        <input
                            type="text"
                            className="form-control"
                            id="foldername"
                            placeholder="folder Name"
                            onChange={(e) => {

                                setFolderName(e.target.value);
                            }}
                        />
                        {/* <input type='file'    onChange={(e)=>{
                            setFolderImage(e.target.files[0])
                        }}/> */}
                        {/* <div className='font-select-box'>                            
                            <Select 
                                options={options} 
                                placeholder="Choose Font"
                            />
                            <select value={selectedFont} onChange={handleFontChange}>
                                <option value="default">Default Font</option>
                                <option value="trocchi-regular">Font 1 (Your Google Font)</option>
                                <option value="fozeyada-regularnt2">Font 2 (Another Google Font)</option>
                            </select>
                            <h1 style={{ fontFamily: selectedFont }}>This heading will change font</h1>
                        </div> */}
                        <div className="file-input mt-2">
                            <input type='file' onChange={(e)=>{
                                setFolderImage(e.target.files[0])
                                }}
                                id="file-input"
                                className="file-input__input"
                            />
                            <label className="file-input__label" for="file-input">
                                <i className="las la-cloud-upload-alt"></i>
                                <span>Upload file</span>
                            </label>
                        </div>
                    </ModalWrapper>
                )}

                
            </div>
        </Layout>
    )
}

export default EventImages

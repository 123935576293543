import React, { useState, useCallback } from "react";
import { useDropzone } from 'react-dropzone'
import { Popover, OverlayTrigger } from 'react-bootstrap'

const ShowError = (props) => {
    return <>{Object.entries(props.ErrorMsg).length > 0 && <div className="alert alert-danger">
        {Object.entries(props.ErrorMsg).map(([key, val]) =>
            <p key={key}>{key}: {val}</p>
        )}
    </div>}</>
}

function dataURLtoFile(dataurl, filename) {

    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
}

  function getUserData(){
    if(localStorage.getItem('userData')){
      return JSON.parse(localStorage.getItem('userData'))
    }
    return {
      message:'you are not logged in',
      token:false,
      userData:[]
    };
  }

  function IsLoggedIn(){
    return localStorage.getItem('IsLoggedIn')?localStorage.getItem('IsLoggedIn'):false;
  }
  
  function getnavigateUrl(url){
    if(getUserData().userData.ShowStartUpPages==1){
      return url
    }else{
      return '/dashboard';
    }
  }

  function getFormData(o) {
    const formData = new FormData();
    return Object.entries(o).reduce((d,e) => (d.append(...e),d), new FormData())
  }

  function getOperatingSystem(window) {
    let userAgent = window.navigator.userAgent;
    let operatingSystem = 'Not known';

    if (/Android/i.test(userAgent)) {
        operatingSystem = 'Android';
    } else if (/iPhone|iPad|iPod/i.test(userAgent)) {
        operatingSystem = 'iOS';
    } else if (/Win/i.test(userAgent)) {
        operatingSystem = 'Windows OS';
    } else if (/Mac/i.test(userAgent)) {
        operatingSystem = 'MacOS';
    } else if (/X11/i.test(userAgent)) {
        operatingSystem = 'UNIX OS';
    } else if (/Linux/i.test(userAgent)) {
        operatingSystem = 'Linux OS';
    }

    return operatingSystem;
  }
  
  function getBrowser(window) {
    let currentBrowser = 'Not known';
    if (window.navigator.userAgent.indexOf('Chrome') !== -1) { currentBrowser = 'Google Chrome'; }
    else if (window.navigator.userAgent.indexOf('Firefox') !== -1) { currentBrowser = 'Mozilla Firefox'; }
    else if (window.navigator.userAgent.indexOf('MSIE') !== -1) { currentBrowser = 'Internet Exployer'; }
    else if (window.navigator.userAgent.indexOf('Edge') !== -1) { currentBrowser = 'Edge'; }
    else if (window.navigator.userAgent.indexOf('Safari') !== -1) { currentBrowser = 'Safari'; }
    else if (window.navigator.userAgent.indexOf('Opera') !== -1) { currentBrowser = 'Opera'; }
    else if (window.navigator.userAgent.indexOf('Opera') !== -1) { currentBrowser = 'YaBrowser'; }
    else { console.log('Others'); }
  
    return currentBrowser;
  }
 
export { ShowError,dataURLtoFile,getUserData,getnavigateUrl,IsLoggedIn,getFormData, getOperatingSystem, getBrowser }
import Layout from "../layout/Layout"
const NotFound = (props) => {
    return <Layout bottom="false" >
    <div className="column top">
    <div className="short-bio">
    <h3>Page Not Found</h3>
    </div>
        
    </div>
    </Layout>
}
export default NotFound
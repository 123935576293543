import React, { useState, useEffect } from "react";
import Axios from "axios";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import Layout from "../layout/Layout";
import Loader from "../../Library/Loader";
import { getUserData, IsLoggedIn, getnavigateUrl } from "../../Library/Helper";
import { filledInputClasses } from "@mui/material";
import PopUpmodal from "../../Library/PopUpmodal";
import AuthContext from "../../store/auth-context";
const Password = () => {
  const LoggedInStatus = IsLoggedIn();
  var location = useLocation();
  const { pathname } = useLocation();
  const BackButton = LoggedInStatus;
  let params = useParams();
  const resetToken = params.confirmationCode;
  const navigate = useNavigate();
  const [Password, setPassword] = useState();
  const [Email, setEmail] = useState(null);
  const [passwordType, setPasswordType] = useState("password");
  const [confirmPasswordType, setConfirmPasswordType] = useState("password");
  const [ConfirmPassword, setConfirmPassword] = useState();
  const [userData, setUserData] = useState(location.state);
  const [ErrorMsg, setErrorMsg] = useState();
  const [errors, setErrors] = useState({});
  const [success, setSuccess] = useState(false);
  let [loading, setLoading] = useState(false);
  const user = getUserData();

  const updatePassword = () => {
    //if (LoggedInStatus) {
    let errors = {};
    if (!Password) {
      errors["password"] = "Please enter password.";
    }

    if (!ConfirmPassword) {
      errors["confirmPassword"] = "Please enter confirm password.";
    }
    if (resetToken) {
      if (!Email) {
        errors["email"] = "Please enter email.";
      }
    }

    if (Password && ConfirmPassword) {
      if (Password !== ConfirmPassword) {
        errors["confirmPassword"] =
          "Password and confirm password does not match.";
      }
    }

    console.log(errors, "- erior s ");

    if (Object.keys(errors)?.length) {
      setErrors(errors);
      return;
    }
    setLoading(true);

    let passwordData = {
      password: Password,
      password_confirmation: ConfirmPassword,
      email: userData ? userData.data.email : Email,
      confirmationCode: userData ? userData.data.confirmation_code : "",
      token: resetToken,
    };
    let token = getUserData() ? getUserData().token : "";
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    var ApiUrl = "password_update";
    if (resetToken) {
      ApiUrl = "auth/password/reset";
    } else {
      ApiUrl = LoggedInStatus ? "auth/update_password" : "password_update";
    }

    localStorage.setItem("emailForLogin", passwordData.email);

    Axios.post(
      process.env.REACT_APP_API_BASE_URL + ApiUrl,
      passwordData,
      config
    )
      .then(function (response) {
        console.log({ resetToken });
        setLoading(false);
        setSuccess(true);
        // navigate(getnavigateUrl('/login'))
        // setLoading(false)
        // console.log(response.data)
        // setErrorMsg('');
        // setResData(response.data)
        // setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
        setErrorMsg(error.response.data.error.message);
      });
    // } else {
    //   setErrorMsg('you can not acess directly this page');
    //   setLoading(false)
    // }
  };

  useEffect(() => {
    console.log(errors, "--- errors ---");
  }, [errors]);
  const handleClose = () => {
    setSuccess(false);
    setLoading(true);
    let email = localStorage.getItem("emailForLogin");
    let requestBody = {
      email: user.userData.email || email,
      password: Password,
    };
    Axios.post(process.env.REACT_APP_API_BASE_URL + "auth/login", requestBody)
      .then(function (response) {
        localStorage.setItem("userData", JSON.stringify(response.data));
        localStorage.setItem("IsLoggedIn", true);
        // AuthContext.login(response.data.token);
        setLoading(false);
        console.log(response.data.userData);

        if (response.data.userData.socialProduct === "true") {
          navigate(
            `/SocialSingleUser/${response.data.userData.socialProductID}?redirect=password`,
            {
              replace: true,
            }
          );
        } else {
          navigate("/dashboard", {
            replace: true,
          });
        }
        window.location.reload();
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
        setErrorMsg(error.response.data.error.message);
      });
  };

  return (
    <Layout bottom="true" BackButton={BackButton}>
      <div className="row justify-content-center">
        <Loader loading={loading} size={150} />
        {success && (
          <PopUpmodal closeButton="true" show="true" clickHandler={handleClose}>
            Password Successfully Updated
          </PopUpmodal>
        )}
        <div className="col-md-9">
          {ErrorMsg && (
            <div className="alert alert-danger">
              <p>{ErrorMsg}</p>
            </div>
          )}
          <div className="register-form">
            <form>
              {resetToken && (
                <div className="mb-3">
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    placeholder="Email"
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                  {errors?.email && (
                    <span className="text-danger fa-xs ">
                      {errors?.email || ""}
                    </span>
                  )}
                </div>
              )}
              <div className="mb-3" style={{ position: "relative" }}>
                <input
                  type={passwordType}
                  className="form-control"
                  id="password"
                  placeholder="Password"
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                />
                <i
                  style={{
                    position: "absolute",
                    top: "15px",
                    right: "20px",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    setPasswordType((prev) =>
                      prev === "text" ? "password" : "text"
                    )
                  }
                  className={`fa ${
                    passwordType === "password" ? "fa-eye" : "fa-eye-slash"
                  }`}
                ></i>
                {errors?.password && (
                  <span className="text-danger fa-xs ">
                    {errors?.password || ""}
                  </span>
                )}
              </div>
              <div className="mb-3" style={{ position: "relative" }}>
                <input
                  type={confirmPasswordType}
                  className="form-control"
                  id="cPassword"
                  placeholder="Confirm Password"
                  onChange={(e) => {
                    setConfirmPassword(e.target.value);
                  }}
                />
                <i
                  style={{
                    position: "absolute",
                    top: "15px",
                    right: "20px",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    setConfirmPasswordType((prev) =>
                      prev === "text" ? "password" : "text"
                    )
                  }
                  className={`fa ${
                    confirmPasswordType === "password"
                      ? "fa-eye"
                      : "fa-eye-slash"
                  }`}
                ></i>

                {errors?.confirmPassword && (
                  <span className="text-danger fa-xs ">
                    {errors?.confirmPassword || ""}
                  </span>
                )}
              </div>
              <div className="btn-nxt">
                <button
                  className="btn btnUI-1"
                  onClick={updatePassword}
                  type="button"
                >
                  {LoggedInStatus ? "Confirm" : "Next"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default Password;

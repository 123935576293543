import React, { useEffect, useState } from "react";
import Loader from "../../Library/Loader";
import Layout from "../layout/Layout";
import Axios from "axios";
import { getnavigateUrl, getUserData } from "../../Library/Helper";
import { useNavigate } from "react-router-dom";

const BackgroundAndProfileColor = () => {
  const QuickColor = [
    "#2E90D8",
    "#FC59A7",
    "#2E90D8",
    "#AD4BFA",
    "#F5953B",
    "#50BF5C",
    "#553BF5",
  ];
  let [loading, setLoading] = useState(false);
  let { userData, token, message } = getUserData();
  const [profileColor, setProfileColor] = useState(
    "linear-gradient(135deg, #D82E2E 23.48%, #312ED8 79.55%)"
  );
  const [backgroundColor, setBackgroundColor] = useState(
    "linear-gradient(135deg, #D82E2E 23.48%, #312ED8 79.55%)"
  );
  const [textColor, setTextColor] = useState(
    "linear-gradient(135deg, #D82E2E 23.48%, #312ED8 79.55%)"
  );
  const [buttonBackgroundColor, setButtonBackgroundColor] = useState(
    "linear-gradient(135deg, #D82E2E 23.48%, #312ED8 79.55%)"
  );
  const [buttonTextColor, setButtonTextColor] = useState(
    "linear-gradient(135deg, #D82E2E 23.48%, #312ED8 79.55%)"
  );
  const navigate = useNavigate();
  const userType = localStorage.getItem("userType");

  useEffect(() => {
    console.log(userData);
    setProfileColor(userData.profilecolour);
    setBackgroundColor(userData.backgroundcolour);
    setTextColor(userData.textcolor);
    setButtonBackgroundColor(userData.buttonbackcolour);
    setButtonTextColor(userData.buttonfrontcolour);
  }, []);

  const NextButtonHandler = async () => {
    let user = {
      token: token,
      message: message,
      userData: userData,
    };
    try {
      setLoading(true);
      const backgroundColorPromise = new Promise(async (resolve, reject) => {
        if (userData.backgroundcolour !== backgroundColor) {
          const res = await Axios.post(
            process.env.REACT_APP_API_BASE_URL + "auth/backgroundcolour",
            { backgroundcolour: backgroundColor, type: userType },
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );

          if (res.status === 200) {
            user = {
              ...user,
              userData: {
                ...user.userData,
                backgroundcolour: backgroundColor,
              },
            };
            resolve();
          }
        } else {
          resolve();
        }
      });

      const profileColorPromise = new Promise(async (resolve, reject) => {
        if (userData.profilecolour !== profileColor) {
          const res = await Axios.post(
            process.env.REACT_APP_API_BASE_URL + "auth/profilecolour",
            { profilecolour: profileColor, type: userType },
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );

          if (res.status === 200) {
            user = {
              ...user,
              userData: {
                ...user.userData,
                profilecolour: profileColor,
              },
            };

            resolve();
          }
        } else {
          resolve();
        }
      });

      const textColorPromise = new Promise(async (resolve, reject) => {
        if (userData.textcolor !== textColor) {
          const res = await Axios.post(
            process.env.REACT_APP_API_BASE_URL + "auth/profiletextcolour",
            { textcolor: textColor, type: userType },
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );

          if (res.status === 200) {
            user = {
              ...user,
              userData: {
                ...user.userData,
                textcolor: textColor,
              },
            };

            resolve();
          }
        } else {
          resolve();
        }
      });

      const buttonBackgroundColorPromise = new Promise(
        async (resolve, reject) => {
          if (userData.buttonbackcolour !== buttonBackgroundColor) {
            const res = await Axios.post(
              process.env.REACT_APP_API_BASE_URL + "auth/buttonbackcolour",
              { buttonbackcolour: buttonBackgroundColor, type: userType },
              {
                headers: { Authorization: `Bearer ${token}` },
              }
            );

            if (res.status === 200) {
              user = {
                ...user,
                userData: {
                  ...user.userData,
                  buttonbackcolour: buttonBackgroundColor,
                },
              };

              resolve();
            }
          } else {
            resolve();
          }
        }
      );

      const buttonTextColorPromise = new Promise(async (resolve, reject) => {
        if (userData.buttonfrontcolour !== buttonTextColor) {
          const res = await Axios.post(
            process.env.REACT_APP_API_BASE_URL + "auth/buttonfrontcolour",
            { buttonfrontcolour: buttonTextColor, type: userType },
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );

          if (res.status === 200) {
            user = {
              ...user,
              userData: {
                ...user.userData,
                buttonfrontcolour: buttonTextColor,
              },
            };

            resolve();
          }
        } else {
          resolve();
        }
      });

      Promise.all([
        backgroundColorPromise,
        profileColorPromise,
        textColorPromise,
        buttonBackgroundColorPromise,
        buttonTextColorPromise,
      ])
        .then(() => {
          localStorage.setItem("userData", JSON.stringify(user));
          navigate(getnavigateUrl("/short-bio"));
          setLoading(false);
          window.location.reload();
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log({ error });
    }
  };

  return (
    <Layout
      bottom="true"
      buttontext="Next"
      bottomClickeventhandler={NextButtonHandler}
      BackButton="true"
    >
      <Loader loading={loading} />
      <div>
        <div className="banner-color">
          <p>Choose Profile Picture Ring color</p>
          <ul className="bannerColor">
            <li className="gradientColor">
              <a
                href="#"
                id="gradient"
                style={{ background: profileColor }}
              ></a>
              <input
                className="color1"
                type="color"
                name="color1"
                defaultValue="#ff0000"
                onChange={(e) => {
                  setProfileColor(e.target.value);
                }}
              />
            </li>
          </ul>
        </div>
        {/* <div className="banner-color">
          <p>Quick colors for Profile Background</p>
          <ul className="bannerColor">
            {QuickColor.map(function (item, index) {
              return (
                <li key={index}>
                  <label
                    onClick={() => {
                      setProfileColor(item);
                    }}
                  >
                    <input type="radio" name="radio" defaultValue="1" />
                    <span style={{ background: item }}>
                      <img src="assets/img/check-icn.svg" alt="Checked Icon" />
                    </span>
                  </label>
                </li>
              );
            })}
          </ul>
        </div> */}
      </div>
      <div>
        <div className="banner-color">
          <p>Choose Background color</p>
          <ul className="bannerColor">
            <li className="gradientColor">
              <a
                href="#"
                id="gradient"
                style={{ background: backgroundColor }}
              ></a>
              <input
                className="color1"
                type="color"
                name="color1"
                defaultValue="#ff0000"
                onChange={(e) => {
                  setBackgroundColor(e.target.value);
                }}
              />
            </li>
          </ul>
        </div>
        {/* <div className="banner-color">
          <p>Quick colors for Background</p>
          <ul className="bannerColor">
            {QuickColor.map(function (item, index) {
              return (
                <li key={index}>
                  <label
                    onClick={() => {
                      setBackgroundColor(item);
                    }}
                  >
                    <input type="radio" name="radio" defaultValue="1" />
                    <span style={{ background: item }}>
                      <img src="assets/img/check-icn.svg" alt="Checked Icon" />
                    </span>
                  </label>
                </li>
              );
            })}
          </ul>
        </div> */}
      </div>
      <div>
        <div className="banner-color">
          <p>Choose Text color</p>
          <ul className="bannerColor">
            <li className="gradientColor">
              <a href="#" id="gradient" style={{ background: textColor }}></a>
              <input
                className="color1"
                type="color"
                name="color1"
                defaultValue="#ff0000"
                onChange={(e) => {
                  setTextColor(e.target.value);
                }}
              />
            </li>
          </ul>
        </div>
        {/* <div className="banner-color">
          <p>Quick colors for Background</p>
          <ul className="bannerColor">
            {QuickColor.map(function (item, index) {
              return (
                <li key={index}>
                  <label
                    onClick={() => {
                      setBackgroundColor(item);
                    }}
                  >
                    <input type="radio" name="radio" defaultValue="1" />
                    <span style={{ background: item }}>
                      <img src="assets/img/check-icn.svg" alt="Checked Icon" />
                    </span>
                  </label>
                </li>
              );
            })}
          </ul>
        </div> */}
      </div>
      <div>
        <div className="banner-color">
          <p>Choose Button Background color</p>
          <ul className="bannerColor">
            <li className="gradientColor">
              <a
                href="#"
                id="gradient"
                style={{ background: buttonBackgroundColor }}
              ></a>
              <input
                className="color1"
                type="color"
                name="color1"
                defaultValue="#ff0000"
                onChange={(e) => {
                  setButtonBackgroundColor(e.target.value);
                }}
              />
            </li>
          </ul>
        </div>
        {/* <div className="banner-color">
          <p>Quick colors for Background</p>
          <ul className="bannerColor">
            {QuickColor.map(function (item, index) {
              return (
                <li key={index}>
                  <label
                    onClick={() => {
                      setBackgroundColor(item);
                    }}
                  >
                    <input type="radio" name="radio" defaultValue="1" />
                    <span style={{ background: item }}>
                      <img src="assets/img/check-icn.svg" alt="Checked Icon" />
                    </span>
                  </label>
                </li>
              );
            })}
          </ul>
        </div> */}
      </div>{" "}
      <div>
        <div className="banner-color">
          <p>Choose Button Text color</p>
          <ul className="bannerColor">
            <li className="gradientColor">
              <a
                href="#"
                id="gradient"
                style={{ background: buttonTextColor }}
              ></a>
              <input
                className="color1"
                type="color"
                name="color1"
                defaultValue="#ff0000"
                onChange={(e) => {
                  setButtonTextColor(e.target.value);
                }}
              />
            </li>
          </ul>
        </div>
        {/* <div className="banner-color">
          <p>Quick colors for Background</p>
          <ul className="bannerColor">
            {QuickColor.map(function (item, index) {
              return (
                <li key={index}>
                  <label
                    onClick={() => {
                      setBackgroundColor(item);
                    }}
                  >
                    <input type="radio" name="radio" defaultValue="1" />
                    <span style={{ background: item }}>
                      <img src="assets/img/check-icn.svg" alt="Checked Icon" />
                    </span>
                  </label>
                </li>
              );
            })}
          </ul>
        </div> */}
      </div>
    </Layout>
  );
};

export default BackgroundAndProfileColor;

import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
const PopUpmodal = (props) => {
    const [smShow, setSmShow] = useState(props.show);

    return (
        <>
            <Modal
                size={props.size ? props.size : "sm"}
                show={smShow}
                onHide={() => setSmShow(false)}
                aria-labelledby="example-modal-sizes-title-sm"
            >
                {
                    (props?.headerVisibility !== 'false') && 
                    <Modal.Header closeButton>
                        <Modal.Title id="example-modal-sizes-title-sm">
                            {props.Header}
                        </Modal.Title>
                    </Modal.Header>
                }
                <Modal.Body> 
                    <div className="row text-center">
                        {
                            (props?.imageSuccess) &&
                            <div className="col-sm-12">
                                <i className="checkmark">✓</i>
                                <h1 className="checkmarkMessage">Success</h1> 
                            </div>
                        }

                        {
                            (props?.imageError) &&
                            <div className="col-sm-12">
                                <i className="errorMessage">x</i>
                                <h1 className="checkmarkMessageError">Warning</h1> 
                            </div>
                        }
                        
                        <div className="col-sm-12">
                         <span className="d-block">{props.children}</span> 
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer style={{ display: "flex", justifyContent: "center", }}
                ><Button variant="secondary" onClick={props.clickHandler} centered>Close</Button></Modal.Footer>
            </Modal>

        </>
    );
}

export default PopUpmodal
import { configureStore } from "@reduxjs/toolkit";
import commanSlice from "../slices/commanSlice";

const store = configureStore({
  reducer: {
    common: commanSlice,
  },
});

export default store;

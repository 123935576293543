import React from "react";
import Layout from "../layout/Layout";
import { IsLoggedIn } from "../../Library/Helper";
import { useNavigate,useLocation, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { getUserData } from "./../../Library/Helper";
import Axios from "axios";
import { Link } from "react-router-dom";
import { Image } from "antd";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import axios from "axios";



const EventImagesCollection = () => {
  const LoggedInStatus = IsLoggedIn();
  let params = useParams();
  let folderId = +params.folderId;
  const navigate = useNavigate();

  const location = useLocation();


  const BackButton = LoggedInStatus;
  let [loading, setLoading] = useState(true);
  const [folderImage, setFolderImage] = useState(false);
  const [folderName, setFolderName] = useState();
  const [imageId, setImageId] = useState();


  const [eventFolderId, setEventFolderID] = useState(folderId);
  const [isToggled, setIsToggled] = useState(false);
  const handleToggle = () => {
    setIsToggled(!isToggled); // Toggle the state
  };
  const [isModal, setIsModal] = useState(false);



  useEffect(() => {
    let token = getUserData().token;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    Axios.post(
      process.env.REACT_APP_API_BASE_URL + "auth/get_event_image",
      { event_folder_id: eventFolderId },
      config
    )
      .then(function (response) {
        console.log(response.data.data.folder_name);
        setFolderImage(response.data);
        setFolderName(response.data.data.folder_name);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    document.body.classList.add("event-images-collection-wraper");

    // Cleanup function to remove the class when the component unmounts
    return () => {
      document.body.classList.remove("event-images-collection-wraper");
    };
  }, []);

  const getFileExtension = (url) => {
    const pathname = new URL(url).pathname;
    const extension = pathname.substring(pathname.lastIndexOf(".") + 1);
    return extension;
  };
  const generateRandomId = () => {
    const randomPart = Math.random().toString(36).substr(2, 9);
    const timeStamp = Date.now().toString(36);
    return `${timeStamp}-${randomPart}`;
  };

  const handleDelete = (id) => {
    let token = getUserData().token;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    Axios.post(
      process.env.REACT_APP_API_BASE_URL + "auth/delete_image/",
      { id },
      config
    )
      .then((res) => {
        setFolderImage(prev=>{
          const folderImages = prev.data.folderimage;
            return {...prev,data:{
              folderimage:folderImages.filter(s=>s.id  !== id)
            }}
        })
        console.log(location.pathname);
        // navigate(location.pathname);
      })
      .catch((err) => {
        console.log(err);
      });

  };
  const handleDownload = async (imageSrc) => {
    try {
      const response = await fetch(imageSrc, {
        method: "get",
        headers: {
          "Access-Control-Allow-Methods": "GET, HEAD",
          "Access-Control-Allow-Origin": "*",
        },
      });
      console.log(response, "-response ");
      if (!response.ok) {
        throw new Error("Network response was not ok.");
      }

      const blob = await response.blob();
      console.log("Image converted to blob.");

      const url = URL.createObjectURL(blob);
      console.log("Blob converted to object URL.");

      const link = document.createElement("a");
      const extension = getFileExtension(url);
      const uniqueId = generateRandomId();
      const uniqueFilename = uniqueId + extension;
      link.href = url;
      link.download = uniqueFilename;
      document.body.appendChild(link);
      console.log("Link appended to body.");

      link.click();
      console.log("Link clicked for download.");

      document.body.removeChild(link);
      console.log("Link removed from body.");

      URL.revokeObjectURL(url);
      console.log("Object URL revoked.");
    } catch (error) {
      console.error("Failed to download image", error);
    }
  };

  const ImageDownloader = () => {
    if (folderImage.data.folderimage?.length) {
      let imageUrls = [];
      folderImage.data.folderimage?.map((folderImage) => {
        imageUrls.push(folderImage.image_path);
      });
      const fetchImage = async (url) => {
        const response = await fetch(url);
        const blob = await response.blob();
        return blob;
      };

      const downloadImagesAsZip = async () => {
        const zipName = generateRandomId();
        const zip = new JSZip();
        const imgFolder = zip.folder(zipName);

        for (let i = 0; i < imageUrls.length; i++) {
          const url = imageUrls[i];
          const blob = await fetchImage(
            process.env.REACT_APP_Img_BASE_URL_FRONT + url
          );
          imgFolder.file(`image${i + 1}.jpg`, blob);
        }

        zip.generateAsync({ type: "blob" }).then((content) => {
          saveAs(content, `${zipName}.zip`);
        });
      };
      downloadImagesAsZip();
    }
  };

  return (
    <Layout>
      <div className="col-md-12">
        <div className="create-folder-btn-wraper">
          <h4>{folderName}</h4>
          <div className="download-btn-wraper">
            <button
              onClick={() => {
                ImageDownloader();
              }}
              type="button"
              className="btn"
            >
              <img src="../download-icon.svg" alt={`${folderName}`} /> Download
              All
            </button>
          </div>
        </div>
        <div className="gallery-box-container">
          <div className="row">
            <div className="col-12">
              <div className="grid row g-3">
                <Image.PreviewGroup
                  preview={{
                    onChange: (current, prev) =>
                      console.log(
                        `current index: ${current}, prev index: ${prev}`
                      ),
                  }}
                >
                  {folderImage &&
                    folderImage.data.folderimage.map(function (
                      { id, image_path },
                      index
                    ) {
                      return (
                        <div className="col-6" key={index}>
                          <div className="card-gallery">
                            <button
                              className="gallery-download-btn"
                              type="button"
                              onClick={() =>
                                handleDownload(
                                  process.env.REACT_APP_Img_BASE_URL_FRONT +
                                  image_path
                                )
                              }
                            >
                              <img
                                src="../download-icon-white.svg"
                                alt={`tapitag`}
                              />
                            </button>
                            <button type='button' className='gallery-delete-btn' folderid={id} onClick={() => {
                              handleDelete(id)
                            }} >
                              <img
                                src="../delete-icon-white.svg"
                                alt={`tapitag`}
                              />
                            </button>

                            <Image
                              width={200}
                              src={
                                process.env.REACT_APP_Img_BASE_URL_FRONT +
                                image_path
                              }
                              className="img-fluid"
                            />
                          </div>
                        </div>
                      );
                    })}
                </Image.PreviewGroup>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default EventImagesCollection;
